<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-3">
              <select v-model="search_data.hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub is required</div>
            </div>
            <div class="col-md-3">
              <select v-model="search_data.download_file_tag" v-validate="{ required: true }" id="download_file_tag" name="download_file_tag" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a download type</option>
                <option value="excel">Excel</option>
              </select>
              <div class="invalid-feedback">Type is required</div>
            </div>
            <div class="col-md-3">
              <select v-model="search_data.month_range" v-validate="{ required: true }" id="month_range" name="month_range" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a month range</option>
                <option value="5">6</option>
                <option value="11">12</option>
              </select>
              <div class="invalid-feedback">Month range is required</div>
            </div>
            <div class="col-md-3">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Download</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-2" v-if="hub_arrays.length > 0">
          <div class="col-md-12 float-right">
            <div class="float-right">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <button type="button" class="btn btn-outline-success">
                  <i class="fe fe-more-vertical" /> Download
                </button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                  </a-menu-item>
<!--                  <a-menu-item>-->
<!--                    <a-button class="btn btn-outline-success btn-block mr-2"  @click.prevent="tableToExcel('all_hub_fuel_requisition_list', 'All Hub Requisition & Consumption')"><i class="fa fa-file-excel-o mr-1"></i> Excel From Table View</a-button>-->
<!--                  </a-menu-item>-->
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" ref="all_hub_fuel_requisition_list" id="all_hub_fuel_requisition_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Hub</th>
              <th v-for="(previous_month, index) in previous_months" :key="index">
                {{ previous_month['month_name'] }} - {{ previous_month['year'] }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub_array, index) in hub_arrays" :key="index">
              <td>{{ index + 1}}</td>
              <td>{{ hub_array.name }}</td>
              <td v-for="(fuel_request_approved_qtys, i) in hub_array['fuel_request_approved_qtys']" :key="i">
                Approved(Ltr): <strong>{{ fuel_request_approved_qtys['total_requisite_liter'] }}</strong> <br> Consumption(Ltr): <strong>{{ fuel_request_approved_qtys['total_fuel_liter'] }}</strong>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hub_arrays.length === 0 && flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hubs: [],
      hub_arrays: [],
      previous_months: [],
      index: -1,
      excelDownloadLoader: false,
      loading: false,
      btnLoading: false,
      loader: false,
      flag: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      uri: 'data:application/vnd.ms-excel;base64,',
      template: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64: function (s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format: function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p]
        })
      },
      search_data: {
        hub_id: '',
        download_file_tag: 'excel',
        month_range: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true
          this.btnLoading = true
          this.flag = false
          apiClient.post('api/report/all-hub/monthly/fuel-requisition-consumption/search', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.previous_months = response.data.previous_months
              this.hub_arrays = response.data.hubs
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    excelDownload(index) {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.excelDownloadLoader = true
          apiClient.post('api/report/all-hub/monthly/uel-requisition-consumption/download', this.search_data, { responseType: 'blob' }).then(response => {
            this.excelDownloadLoader = false
            if (response.data.size === 68) {
              this.$notification.error({
                message: 'No data found, in this search query',
              })
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
              const link = document.createElement('a')
              link.href = url
              if (this.search_data.download_file_tag === 'pdf') {
                link.setAttribute('download', 'all_hub_monthly_fuel_requisition_consumption_report.pdf')
              }
              if (this.search_data.download_file_tag === 'excel') {
                link.setAttribute('download', 'all_hub_monthly_fuel_requisition_consumption_report.xlsx')
              }
              document.body.appendChild(link)
              link.click()
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = document.getElementById(table)
      var ctx = {
        worksheet: name || 'Worksheet',
        table: table.innerHTML,
      }
      window.location.href = this.uri + this.base64(this.format(this.template, ctx))
    },
    months() {
      return this.$months()
    },
    years() {
      return this.$years()
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
